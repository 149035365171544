import numeral from 'numeral'

const formatPrice = price => {
  if (price <= 100000) {
    return numeral(price).format('$0,')
  } else if (price <= 1000000 || (price / 1000000) % 1 === 0) {
    return numeral(price).format('$0a')
  } else if (
    Number(price / 1000000)
      .toString()
      .split('.').length === 2
  ) {
    return numeral(price).format('$0.0a')
  }

  return numeral(price).format('$0.00a')
}

export default formatPrice
