// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Particles = require("../../../uikit/reason/Styles/Particles.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var ReactResponsive = require("react-responsive").default;
var ReactResponsive$1 = require("react-responsive");
var JsxRuntime = require("react/jsx-runtime");

function toProps(v) {
  switch (v.TAG) {
    case "MediaQuery" :
        return {
                query: v._0
              };
    case "MinWidth" :
        return {
                minWidth: v._0
              };
    case "MaxWidth" :
        return {
                maxWidth: v._0
              };
    
  }
}

var JS = {};

function useMediaQuery(input) {
  return ReactResponsive$1.useMediaQuery(toProps(input));
}

function isMobile(param) {
  return ReactResponsive$1.useMediaQuery(toProps({
                  TAG: "MaxWidth",
                  _0: Particles.Breakpoint.sm
                }));
}

function isDesktop(param) {
  return ReactResponsive$1.useMediaQuery(toProps({
                  TAG: "MinWidth",
                  _0: Particles.Breakpoint.md
                }));
}

function isLG(param) {
  return ReactResponsive$1.useMediaQuery(toProps({
                  TAG: "MinWidth",
                  _0: Particles.Breakpoint.lg
                }));
}

function isXL(param) {
  return ReactResponsive$1.useMediaQuery(toProps({
                  TAG: "MinWidth",
                  _0: Particles.Breakpoint.xl
                }));
}

function isSM(param) {
  return ReactResponsive$1.useMediaQuery(toProps({
                  TAG: "MinWidth",
                  _0: Particles.Breakpoint.sm
                }));
}

function MediaQuery(props) {
  var children = props.children;
  var __renderWhenQueryIs = props.renderWhenQueryIs;
  var query = props.query;
  var renderWhenQueryIs = __renderWhenQueryIs !== undefined ? __renderWhenQueryIs : true;
  var renderFunction = function (queryMatches) {
    if (queryMatches === renderWhenQueryIs) {
      return children;
    } else {
      return null;
    }
  };
  switch (query.TAG) {
    case "MediaQuery" :
        return JsxRuntime.jsx(ReactResponsive, {
                    query: query._0,
                    children: renderFunction
                  });
    case "MinWidth" :
        return JsxRuntime.jsx(ReactResponsive, {
                    minWidth: Caml_option.some(query._0),
                    children: renderFunction
                  });
    case "MaxWidth" :
        return JsxRuntime.jsx(ReactResponsive, {
                    maxWidth: Caml_option.some(query._0),
                    children: renderFunction
                  });
    
  }
}

var Breakpoint;

var make = MediaQuery;

exports.Breakpoint = Breakpoint;
exports.toProps = toProps;
exports.JS = JS;
exports.useMediaQuery = useMediaQuery;
exports.isMobile = isMobile;
exports.isDesktop = isDesktop;
exports.isLG = isLG;
exports.isXL = isXL;
exports.isSM = isSM;
exports.make = make;
/* react-responsive Not a pure module */
