// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Media = require("../../../../reason/common/Media/Media.bs.js");
var React = require("react");
var ViewStack = require("../../../../reason/common/ViewStack.bs.js");
var Icon__Jsx3 = require("../../atoms/Icon/Icon__Jsx3.bs.js");
var MediaQuery = require("../../../../reason/common/Media/MediaQuery.bs.js");
var Responsive = require("../../helpers/Responsive.bs.js");
var View__Jsx3 = require("../../helpers/View__Jsx3.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var ReactRender = require("../../../../reason/utils/ReactRender.bs.js");
var Core__Option = require("@rescript/core/src/Core__Option.bs.js");
var Theme__Color = require("../../../../reason/common/Theme/Theme__Color.bs.js");
var Buttons__Jsx3 = require("../../atoms/Buttons/Buttons__Jsx3.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var Theme__Color__Vars = require("../../../../reason/common/Theme/Theme__Color__Vars.bs.js");

function selectClass(c) {
  return "." + c;
}

var alertInner = Curry._1(Css.style, {
      hd: Media.maxWidth("sm")({
            hd: Css.flexWrap("wrap"),
            tl: /* [] */0
          }),
      tl: /* [] */0
    });

var alertText = Curry._1(Css.style, {
      hd: Media.maxWidth("sm")({
            hd: Css.lineHeight({
                  NAME: "px",
                  VAL: 20
                }),
            tl: {
              hd: Css.width({
                    NAME: "percent",
                    VAL: 100
                  }),
              tl: /* [] */0
            }
          }),
      tl: /* [] */0
    });

var icon = Curry._1(Css.style, {
      hd: Css.marginRight({
            NAME: "px",
            VAL: 16
          }),
      tl: {
        hd: Css.position("relative"),
        tl: {
          hd: Css.top({
                NAME: "px",
                VAL: 2
              }),
          tl: {
            hd: Css.display("inline"),
            tl: {
              hd: Css.verticalAlign("baseline"),
              tl: /* [] */0
            }
          }
        }
      }
    });

var close = Curry._1(Css.style, {
      hd: Css.position("static"),
      tl: {
        hd: Css.fontSize({
              NAME: "px",
              VAL: 12
            }),
        tl: {
          hd: Css.$$float("none"),
          tl: {
            hd: Css.color(Theme__Color__Vars.white),
            tl: {
              hd: Css.opacity(0.7),
              tl: {
                hd: Css.background("transparent"),
                tl: {
                  hd: Css.unsafe("appearance", "none"),
                  tl: {
                    hd: Css.borderWidth("zero"),
                    tl: {
                      hd: Css.cursor("pointer"),
                      tl: {
                        hd: Css.hover({
                              hd: Css.opacity(1.0),
                              tl: /* [] */0
                            }),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var actionsHolder = Curry._1(Css.style, {
      hd: Media.maxWidth("md")({
            hd: Css.width({
                  NAME: "percent",
                  VAL: 100
                }),
            tl: {
              hd: Css.justifyContent("flexEnd"),
              tl: /* [] */0
            }
          }),
      tl: /* [] */0
    });

var primaryAlert = Curry._1(Css.style, {
      hd: Css.padding2({
            NAME: "px",
            VAL: 10
          }, "zero"),
      tl: {
        hd: Css.fontSize({
              NAME: "px",
              VAL: 16
            }),
        tl: {
          hd: Css.fontWeight({
                NAME: "num",
                VAL: 300
              }),
          tl: /* [] */0
        }
      }
    });

var secondaryAlert = Curry._1(Css.style, {
      hd: Css.padding2({
            NAME: "px",
            VAL: 8
          }, "zero"),
      tl: {
        hd: Css.fontSize({
              NAME: "px",
              VAL: 14
            }),
        tl: {
          hd: Css.fontWeight({
                NAME: "num",
                VAL: 600
              }),
          tl: {
            hd: Css.borderWidth({
                  NAME: "px",
                  VAL: 1
                }),
            tl: {
              hd: Css.borderStyle("solid"),
              tl: {
                hd: Css.child("." + alertInner, {
                      hd: Css.padding2({
                            NAME: "px",
                            VAL: 0
                          }, {
                            NAME: "px",
                            VAL: 10
                          }),
                      tl: /* [] */0
                    }),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var danger = Curry._1(Css.style, {
      hd: Css.backgroundColor(Theme__Color__Vars.crimson),
      tl: {
        hd: Css.color(Theme__Color__Vars.white),
        tl: {
          hd: Css.selector("&." + secondaryAlert, {
                hd: Css.borderColor(Theme__Color__Vars.monza),
                tl: {
                  hd: Css.backgroundColor(Theme__Color__Vars.cherub),
                  tl: {
                    hd: Css.color(Theme__Color__Vars.carmine),
                    tl: /* [] */0
                  }
                }
              }),
          tl: /* [] */0
        }
      }
    });

var success = Curry._1(Css.style, {
      hd: Css.backgroundColor(Theme__Color__Vars.chelseaCucumber),
      tl: {
        hd: Css.color(Theme__Color__Vars.white),
        tl: {
          hd: Css.selector("&." + secondaryAlert, {
                hd: Css.borderColor(Theme__Color__Vars.apple),
                tl: {
                  hd: Css.backgroundColor(Theme__Color__Vars.peppermint),
                  tl: {
                    hd: Css.color(Theme__Color__Vars.vidaLoca),
                    tl: /* [] */0
                  }
                }
              }),
          tl: /* [] */0
        }
      }
    });

var info = Curry._1(Css.style, {
      hd: Css.backgroundColor(Theme__Color__Vars.wedgewood),
      tl: {
        hd: Css.color(Theme__Color__Vars.white),
        tl: {
          hd: Css.selector("&." + secondaryAlert, {
                hd: Css.borderColor(Theme__Color__Vars.calypso),
                tl: {
                  hd: Css.backgroundColor(Theme__Color__Vars.mystic),
                  tl: {
                    hd: Css.color(Theme__Color__Vars.calypso),
                    tl: /* [] */0
                  }
                }
              }),
          tl: /* [] */0
        }
      }
    });

var warning = Curry._1(Css.style, {
      hd: Css.backgroundColor(Theme__Color__Vars.galliano),
      tl: {
        hd: Css.color(Theme__Color__Vars.white),
        tl: {
          hd: Css.selector("&." + secondaryAlert, {
                hd: Css.borderColor(Theme__Color__Vars.galliano),
                tl: {
                  hd: Css.backgroundColor(Theme__Color__Vars.galliano),
                  tl: {
                    hd: Css.backgroundColor(Theme__Color.setAlpha("03", Theme__Color.galliano)),
                    tl: {
                      hd: Css.color(Theme__Color__Vars.galliano),
                      tl: /* [] */0
                    }
                  }
                }
              }),
          tl: /* [] */0
        }
      }
    });

var Styles = {
  selectClass: selectClass,
  alertInner: alertInner,
  alertText: alertText,
  icon: icon,
  close: close,
  actionsHolder: actionsHolder,
  primaryAlert: primaryAlert,
  secondaryAlert: secondaryAlert,
  danger: danger,
  success: success,
  info: info,
  warning: warning
};

function iconOfMessageType(x) {
  if (x === "error") {
    return "Attention";
  } else {
    return "Tick";
  }
}

function mainClassName(secondary) {
  if (secondary) {
    return secondaryAlert;
  } else {
    return primaryAlert;
  }
}

function renderButtons(id, dismissible, handleOnDismiss, children) {
  var shouldRender = Core__Option.isSome(children) || dismissible;
  var isDesktop = MediaQuery.isDesktop();
  var onDismiss = Core__Option.getOr(handleOnDismiss, (function (param) {
          
        }));
  return ReactRender.ifTrue(JsxRuntime.jsxs(ViewStack.make, {
                  space: "md",
                  alignItems: "center",
                  flexDirection: "row",
                  className: actionsHolder,
                  children: [
                    ReactRender.ifTrue(JsxRuntime.jsx(Buttons__Jsx3.OutlineButton.make, {
                              onClick: (function (param) {
                                  Curry._1(onDismiss, id);
                                }),
                              children: "Dismiss"
                            }), !isDesktop && dismissible),
                    children !== undefined ? JsxRuntime.jsx(View__Jsx3.make, {
                            flexDirection: "row",
                            children: Caml_option.some(Caml_option.valFromOption(children))
                          }) : null,
                    ReactRender.ifTrue(JsxRuntime.jsx("button", {
                              children: JsxRuntime.jsx(Icon__Jsx3.make, {
                                    icon: "Cancel"
                                  }),
                              className: close,
                              onClick: (function (param) {
                                  Curry._1(onDismiss, id);
                                })
                            }), isDesktop && dismissible)
                  ]
                }), shouldRender);
}

function classNameOfMessageType(x) {
  if (x === "error") {
    return danger;
  } else if (x === "warning") {
    return warning;
  } else if (x === "success") {
    return success;
  } else {
    return info;
  }
}

function SystemMessage(props) {
  var handleOnDismiss = props.handleOnDismiss;
  var __icon = props.icon;
  var __secondary = props.secondary;
  var __dismissible = props.dismissible;
  var type_ = props.type_;
  var __time = props.time;
  var id = props.id;
  var time = __time !== undefined ? __time : 3000;
  var dismissible = __dismissible !== undefined ? __dismissible : false;
  var secondary = __secondary !== undefined ? __secondary : false;
  var icon$1 = __icon !== undefined ? __icon : false;
  var className = secondary ? secondaryAlert : primaryAlert;
  var styleClassName = classNameOfMessageType(type_);
  var classNames = className + (" " + styleClassName);
  React.useEffect((function (param) {
          if (handleOnDismiss === undefined) {
            return ;
          }
          if (time <= 0) {
            return ;
          }
          var timeout = setTimeout((function (param) {
                  Curry._1(handleOnDismiss, id);
                }), time);
          return (function (param) {
                    clearTimeout(timeout);
                  });
        }), [time]);
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsxs(ViewStack.make, {
                    mx: "auto",
                    px: {
                      NAME: "px",
                      VAL: 16
                    },
                    space: "md",
                    width: {
                      NAME: "percent",
                      VAL: 100
                    },
                    maxWidth: Responsive.make(undefined, undefined, {
                          NAME: "px",
                          VAL: 720
                        }, {
                          NAME: "px",
                          VAL: 960
                        }, {
                          NAME: "px",
                          VAL: 1140
                        }, {
                          NAME: "px",
                          VAL: 1320
                        }, undefined),
                    alignItems: Responsive.make("flexStart", undefined, "center", undefined, undefined, undefined, undefined),
                    justifyContent: "spaceBetween",
                    flexDirection: Responsive.make("column", undefined, "row", undefined, undefined, undefined, undefined),
                    className: alertInner,
                    children: [
                      JsxRuntime.jsxs("div", {
                            children: [
                              ReactRender.ifTrue(JsxRuntime.jsx(Icon__Jsx3.make, {
                                        icon: iconOfMessageType(type_),
                                        className: icon
                                      }), icon$1),
                              JsxRuntime.jsx("span", {
                                    children: props.text,
                                    className: alertText
                                  })
                            ]
                          }),
                      renderButtons(id, dismissible, handleOnDismiss, props.children)
                    ]
                  }),
              className: classNames
            });
}

var make = SystemMessage;

exports.Styles = Styles;
exports.iconOfMessageType = iconOfMessageType;
exports.mainClassName = mainClassName;
exports.renderButtons = renderButtons;
exports.classNameOfMessageType = classNameOfMessageType;
exports.make = make;
/* alertInner Not a pure module */
