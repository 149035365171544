// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var CssJs = require("bs-css-emotion/src/CssJs.bs.js");
var Theme = require("../Theme/Theme.bs.js");

function maxWidth(v) {
  var partial_arg = "(max-width: " + (Theme.Breakpoints.string_of_px(Theme.Breakpoints.$$eval(v)) + ")");
  return function (param) {
    return Css.media(partial_arg, param);
  };
}

function minWidth(v) {
  var partial_arg = "(min-width: " + (Theme.Breakpoints.string_of_px(Theme.Breakpoints.$$eval(v)) + ")");
  return function (param) {
    return Css.media(partial_arg, param);
  };
}

function minWidthJs(v, x) {
  return CssJs.media("(min-width: " + (Theme.Breakpoints.string_of_px(Theme.Breakpoints.$$eval(v)) + ")"), x);
}

function maxWidthJs(v, x) {
  return CssJs.media("(max-width: " + (Theme.Breakpoints.string_of_px(Theme.Breakpoints.$$eval(v)) + ")"), x);
}

exports.maxWidth = maxWidth;
exports.minWidth = minWidth;
exports.minWidthJs = minWidthJs;
exports.maxWidthJs = maxWidthJs;
/* Css Not a pure module */
